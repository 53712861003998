<template>
  <v-card
    class="min-h-full mx-auto mt-8 common_background"
    :loading="loading"
    max-width="450"
    elevation="4"
  >
    <v-form ref="frmRegister" class="d-flex flex-column align-start pa-4" @submit.prevent="onRegister">
      <v-card-title>
        <h2>
          <b>{{ $t("Register") }}</b>
        </h2>
      </v-card-title>

      <v-card-text>
        <v-text-field
          v-model="email"
          :label="$t('Email')"
          :rules="rules"
          hide-details="auto"
          @keyup="resetNotification()"
        />
        <br />
        <v-text-field
          v-model="password"
          :label="$t('Password')"
          :rules="rules"
          hide-details="auto"
          type="password"
          @keyup="resetNotification()"
        />
        <br />
        <br />
        <v-btn type="submit" block color="green" class="text-capitalize"> Sign Up </v-btn>
        <br />
        <v-row dense class="text-right">
          <v-col cols="12">
            Already registered
            <routerLink class="no-underline" color="primary" :to="{ name: 'Login' }">
              sign in?
            </routerLink>
          </v-col>
        </v-row>
        <v-row dense class="mt-4 text-center">
          <v-col>
            <span
              v-if="!!notificationDetail && notificationDetail.isNotification"
              :class="notificationDetail.notificationType==='error'? 'red--text':'green--text'"
            >
              {{ notificationDetail.message }}
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import {
  FIREBASE_CREATEUSER_WITH_EMAIL_PASSWORD,
} from '@/store/account/action'
import { get, commit, dispatch } from 'vuex-pathify'
import firebase from 'firebase'

export default {
  name:'Register',
  data: () => ({
    loading: false,
    selection: 1,
    rules: [
      (value) => !!value || 'Required.',
      (value) => (value && value.length >= 3) || 'Min 3 characters',
    ],
    email:'',
    password:'',
  }),
  computed:{
    ...get('account', ['notificationDetail']),
  },
  methods: {
    resetNotification() {
      const notificationDetail = {
        message: '',
        pageDetails: '',
        apidetails: '',
        notificationType: '',
        isNotification: false,
      }

      commit('account/SET_MESSAGE', notificationDetail)
    },
    async onRegister() {
      this.loading = true
      if (this.$refs.frmRegister.validate()) {
        const payload = {
          email: this.email,
          password: this.password,
        }

        await dispatch(`account/${FIREBASE_CREATEUSER_WITH_EMAIL_PASSWORD}`, payload)
        this.loading = false
      }
    },
  },
}
</script>
